// Here you can add other styles
._loading_overlay_overlay {
  z-index: 9999 !important;
  background: rgb(40 40 40 / 50%) !important;
}

.form-group.required .control-label:before {
  content: '*';
  color: red;
}

.form-control {
  &.is-invalid {
    border-color: red;
    &::-webkit-input-placeholder {
      color: red;
    }
    &:-moz-placeholder {
      color: red;
    }
    &:-ms-input-placeholder {
      color: red;
    }
    &::-moz-placeholder {
      color: red;
    }
  }
}

.text-hiden {
  display: none;
}

.lb-upload {
  cursor: pointer;
  color: #26b7d2;
  margin: 5px 8px;
}

#upload-photo,
#upload-bg,
#upload-logo-brand,
#upload-photo-merchant,
#upload-photo-merchant-intro,
#upload-loyalty-scheme,
#upload-photo-merchant-contract,
#upload-photo-merchant-menu {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#upload-photo-review {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.cv-button-upload {
  width: 100%;
  .cv-button-upload & {
    width: 400px;
  }
}

.cv-bt-left {
  float: left;
}

.bt-remove-img {
  float: right;
  margin: 5px 8px;
  color: #ff030a;
  cursor: pointer;
}

// .cke_contents {
//   /*height: 120px !important;*/
// }

@media (max-width: 576px) {
  .flex-column-reverse-sx {
    flex-direction: column-reverse;
  }
}

.filter-gift .py-3 {
  padding-left: 5px;
  padding-right: 5px;
}

.filter-gift .inp-point-filter {
  float: left;
  width: 45%;
}

.filter-gift .slash-from-to {
  float: left;
  font-size: 25px;
  margin: 0 2px;
}

.filter-gift .custom-inp-filter {
  max-width: 15%;
  flex: 0 0 15%;
}

.filter-gift .custom-inp-filter-end {
  max-width: 23.3%;
  -webkit-flex: 0 0 23.3%;
  flex: 0 0 23.3%;
}

.cv-btn-gift .btn,
.cv-btn-merchant .btn {
  width: 90px;
  margin: 10px 0 10px 10px;
}

.switch-status {
  float: left;
  margin-top: 15px;
}

.lb-switch-status {
  float: left;
  padding: 18px 5px;
}

.cv-btn-gift {
  float: right;
  margin: 0;
}

.recommend-thumbnail {
  height: 250px;
  width: 100%;
  float: left;
  background: #ccc;
  text-align: center;
  line-height: 250px;
  font-size: 35px;
  font-weight: 500;
}

.recommend-thumbnail-gift {
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: contain;
  float: left;
  background: #ccc;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 35px;
  font-weight: 500;
}

.banner-loyalty {
  min-height: 190px;
  max-height: 220px;
  width: auto;
  float: left;
  background: #ccc;
  text-align: center;
  line-height: 190px;
  font-size: 35px;
  font-weight: 500;
}

.recommend-icon {
  height: auto;
  width: 300px;
  float: left;
  background: #ccc;
  text-align: center;
  line-height: 190px;
  font-size: 35px;
  font-weight: 500;
  &.is-invalid {
    border: 1px solid red;
  }
}

.icon-item {
  height: 190px;
  width: 300px;
}

.cv-end-date-inp .react-datepicker-wrapper {
  float: right;
}

.thumb-gift-item {
  width: 100px;
}

.cv-action-icon a {
  margin: 4px;
}

.cv-action-icon .fa {
  font-size: 17px;
}

.ul-list-item {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 17px;
}

.ul-list-item li {
  margin: 7px 0;
}

.first-col {
  border-right: 1px solid #ccc;
}

/*style for keyword input*/

.ReactTags__tags {
  position: relative;
  border: none;
  padding: 0;
}

.ReactTags__tagInput {
  width: 100%;
  border-radius: 0.25rem;
  display: inline-block;
  margin: 6px 0 0 6px;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: auto;
  padding: 6px 12px;
  margin: 0;
  font-size: 14px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 0.25rem;
}

.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #63bcfd;
  color: white;
  font-size: 13px;
  display: inline-block;
  padding: 2px 2px 2px 5px;
  margin: 0 5px 2px;
  border-radius: 0.25rem;
}
.ReactTags__selected .ReactTags__remove {
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}

.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
  padding: 0 5px;
}

.disable-action {
  opacity: 0.3;
}

.input-form-datepicker {
  height: 40px;
}

/*end style for keyword input*/

.filter-point {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

._loading_overlay_content {
  margin-top: 50vh !important;
}

.content-settings-cbutton-height {
  height: 40px;
}

.rc-time-picker-input {
  height: 35px !important;
}

.rc-time-picker-clear {
  top: 7px !important;
}

.mission-question-add {
  flex: 1;
}

.mission-question-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: black 1px solid;
  margin-top: 6px;
  margin-right: 10px;
}

.mission-question-background {
  background-color: #d7d5d5;
  color: black;
  border-radius: 15px;
  padding: 20px;
}

.mission-question-square {
  width: 10px;
  height: 10px;
  border: black 1px solid;
  margin-top: 6px;
  margin-right: 10px;
}

.mission-break-line {
  width: 100%;
  border: 2px solid #a4a4a4;
  margin-left: -1px;
}

.mission-line-height {
  margin-left: 15px;
  margin-right: 15px;
  height: 100%;
  border-left: 2px solid #a4a4a4;
}

.mission-swtich-status .react-switch-bg {
  height: 20px !important;
}

.mission-swtich-status .react-switch-bg :first-child {
  height: 20px !important;
}

.mission-swtich-status .react-switch-handle {
  height: 18px !important;
  width: 20px !important;
}

.mission-text-underline {
  text-decoration-line: underline;
  text-underline-offset: 2px;
}

.mission-border-dotted {
  border-bottom: 1px dotted black;
}

.mission-button-center {
  margin-top: 10px;
}

#mission-upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.mission-lb-upload {
  cursor: pointer;
  color: #26b7d2;
  margin: 5px 8px;
}

.mission-btn .btn {
  width: 110px;
  margin: 10px 0 10px 10px;
}

.cv-btn-center {
  width: 100%;
  text-align: center;
}

.cv-btn-center .btn {
  margin: 0 5px;
}

.cv-image-padding {
  padding: 0 12%;
}

.mission-remove-question .fa {
  transform: scale(1.7, 1.7);
}

.mission-index-answer {
  z-index: 1055;
}

.mission-index-question {
  z-index: 1050;
}

.thumbnail-item {
  height: 190px;
  width: auto;
  max-width: 100%;
}

.thumbnail-item-gift {
  aspect-ratio: 5 / 4;
  width: 100%;
  object-fit: contain;
  max-width: 100%;
}

.thumbnail-item-brand {
  min-height: 130px;
  max-width: 100%;
}

.thumbnail-merchant {
  max-width: 65%;
  img {
    max-width: 100%;
    min-height: 100px;
  }
}

.thumbnail-gift {
  max-width: 90%;
  img {
    max-width: 100%;
    min-height: 200px;
  }
}

.thumbnail-item-game {
  max-height: 220px;
  max-width: 400px;
  min-height: 190px;
}

.thumbnail-item-game-review {
  max-height: 270px;
  max-width: 400px;
}

.logo-gift-category {
  background: #ccc;
  text-align: center;
  line-height: 250px;
  font-size: 35px;
  font-weight: 500;
  width: 90%;
  display: inline-block;
  .upload-image & {
    width: 400px;
    line-height: 200px;
  }
}

.game-scene {
  width: auto;
  max-width: 100%;
}

.lb-upload-video {
  background: #39f;
  color: #fff;
  padding: 7px;
  cursor: pointer;
  border-radius: 4px;
}

#upload-video {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.cls-hide {
  display: none;
}

.cls-show {
  display: block;
}

.cv-mobile-review {
  width: 100%;
  float: left;
  height: 440px;
  text-align: center;
  position: relative;
}

.cv-mobile-review .preview-bg {
  width: 250px;
  height: 440px;
}

.cv-mobile-review .preview-content {
  position: relative;
  width: 223px;
  padding: 0 5px 0 7px;
  margin: -390px auto;
  height: 307px;
  overflow-y: scroll;
}

.cv-mobile-review .preview-content img {
  width: 100% !important;
}

.cv-mobile-review .preview-content .preview-title {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
  line-height: 19px;
}

.cv-mobile-review .preview-content .preview-img {
  width: 100%;
  border-radius: 5px;
}

.cv-mobile-review .preview-content .preview-time-share {
  width: 100%;
  margin: 5px 0;
}

.cv-mobile-review .preview-content .cover-content-preview {
  width: 100%;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
}

.link-to {
  margin-top: 25px;
  display: inline-block;
  text-decoration: underline;
}

.status {
  margin-top: 10px;
  span {
    padding-left: 15px;
    &.status-item {
      color: red;
      font-weight: 500;
    }
  }
}

.mission-time-picker .rc-time-picker-clear {
  display: none;
}

._loading_overlay_spinner {
  position: fixed !important;
}

/* width */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mission-group-correct {
  display: flex;
  margin-top: 15px;
}

.mission-correct {
  margin-top: 40px;
  margin-left: 15px !important;
}

.mission-time-picker {
  width: 100%;
  height: 45px;
}

.mission-date-picker .react-datepicker-wrapper {
  width: 100% !important;
}

.label-link {
  cursor: pointer;
  color: #26b7d2;
  margin: 10px;
}

.label-link:hover,
.add-gift-enable:hover {
  text-decoration: underline;
}

.add-gift-enable {
  cursor: pointer;
  color: #26b7d2;
  line-height: 18px;
}

.add-gift-disable {
  color: #ccc;
  line-height: 18px;
}

.add-gift-label {
  padding-top: 6px;
  margin: 0;
}

.item-setting-game {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  margin-right: -10px;
}

.item-setting-game .item {
  float: left;
  padding: 0 5px;
}

.item-setting-game .first {
  width: 6%;
  padding-top: 12px;
  float: left;
}

.item-setting-game .first-details {
  width: 8%;
  padding-top: 12px;
  float: left;
}

.item-setting-game .second {
  width: 27%;
  float: left;
}

.item-setting-game .third {
  width: 11%;
  float: left;
}

.item-setting-game .third-details {
  width: 12%;
  float: left;
}

.item-setting-game .fourth {
  width: 18%;
  float: left;
}

.item-setting-game .fifth {
  width: 35%;
  float: left;
}

.item-setting-game .fifth-VXMM {
  width: 17%;
  float: left;
}

.item-setting-game .sixth {
  width: 3%;
  padding-top: 12px;
  float: left;
}

.item-setting-game .sixth .fa-trash {
  cursor: pointer;
  color: gray;
  font-size: 20px;
}

.react-select__indicator {
  padding: 5px !important;
}

.react-select__control {
  min-height: 35px !important;
}

.content-settings-title-space {
  margin-left: 0px;
}

/*.....create merchant----*/

.logo-thumbnail {
  background: #ccc;
  text-align: center;
  line-height: 250px;
  font-size: 35px;
  font-weight: 500;
  width: 80%;
  display: inline-block;
  .upload-image & {
    width: 400px;
    line-height: 200px;
  }
}

.logo-thumbnail-checkin-settings {
  background: #ccc;
  text-align: center;
  line-height: 200px;
  font-size: 35px;
  font-weight: 500;
  width: 50%;
  display: inline-block;
  .upload-image & {
    width: 350px;
    line-height: 100px;
  }
}

.representative-logo {
  background: #ccc;
  width: 250px;
  height: 250px;
  font-size: 35px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cv-btn-merchant {
  display: flex;
  justify-content: flex-end;
}

.logo-brand {
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 0;
  margin-top: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  .brand-item {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 33.333%;
  }
}

.brand-action-icon {
  font-size: 17px;
  a {
    margin: 7px;
  }
}

.rdt_TableCol_Sortable span:not(.bDTjFE, .ffDXKR) {
  opacity: 0.15;
}

.cv-tier-preview {
  width: 334px;
  height: 185px;
  border-radius: 15px;
  position: relative;
  margin: auto;
  border: 1px solid;
  filter: drop-shadow(0 4px 6px rgba(141, 116, 116, 0.4));
  // @media screen and (min-width: 1550px) {
  //     height: 242px;
  // }
  // @media (min-width: 991px) and (max-width: 1199px) {
  //     height: 130px;
  // }
}

.cv-tier-preview .background-card-image {
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 15px;
}

.cv-tier-preview .preview-content {
  position: relative;
}

.cv-tier-preview .preview-content .preview-logo-merchant {
  position: absolute;
  max-width: 28px;
  left: 16px;
  top: 20px;
}

.cv-tier-preview .preview-content .preview-logo-merchant .preview-icon-merchant {
  max-width: 28px;
}

.cv-tier-preview .preview-content .preview-merchant-name {
  position: absolute;
  left: 50px;
  top: 24px;
  font-weight: bold;
  color: white;
  font-size: 15px;
  margin-left: -3px;
  max-width: 130px;
  line-height: 15px;
}

.cv-tier-preview .preview-content .preview-tier-name {
  position: absolute;
  right: 0px;
  top: 20px;
  height: 30px;
  font-weight: bold;
  color: #d5af41;
  font-size: 15px;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  filter: brightness(1.5);
}

.cv-tier-preview .preview-content .preview-tier-name .preview-tier-name-text {
  margin-top: 3px;
  position: relative;
  margin-left: 5px;
  max-width: 160px;
}

.cv-tier-preview .preview-content .preview-tier-name .preview-tier-name-text .preview-crown-icon {
  max-width: 16px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.cv-tier-preview .preview-content .preview-tier-name .preview-tier-name-text p {
  margin-left: 25px;
  margin-right: 5px;
  white-space: nowrap;
  max-width: 132px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cv-mobile-review-placement {
  // width: 100%;
  // float: left;
  width: fit-content;
  height: 440px;
  text-align: center;
  position: relative;
  margin: 0 auto;
}

.cv-mobile-review-placement .preview-bg {
  width: 250px;
  height: 440px;
}

.cv-mobile-review-placement .preview-content {
  position: relative;
  width: 223px;
  padding: 0 5px 0 7px;
  // margin: -390px auto;
  margin: -390px 12px;
  height: 307px;
  overflow-y: scroll;
  background-color: white;
}

// .cv-mobile-review-placement .preview-content .preview-title {
//     color: white;
//     font-size: 15px;
//     font-weight: bold;
//     text-align: left;
//     margin-bottom: 10px;
//     line-height: 19px;
// }
.cv-mobile-review-placement .preview-content .preview-img {
  margin: -2px -5px 0px -13px;
  width: 108%;
  height: 30%;
}

.cv-mobile-review-placement .preview-content .preview-title {
  width: 100%;
}

.cv-mobile-review-placement .preview-content .box-group {
  width: 211px;
  display: flex;
  overflow: auto;
}

.cv-mobile-review-placement .preview-content .box-group::-webkit-scrollbar {
  height: 3px;
  width: 3px;
  background: #000;
  border-radius: 5px;
}

.cv-mobile-review-placement .preview-content .box-group .box-item-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.cv-mobile-review-placement .preview-content .box-group .box-item-scroll .box-item {
  background-color: white;
  width: 97px;
  height: 122px;
  margin: 5px 5px 5px 0px;
  border-radius: 5px;
}

.cv-mobile-review-placement .preview-content .box-group .box-item-scroll .box-item .item-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.cv-mobile-review-placement .preview-content .box-group .box-item-scroll .box-item .item-list {
  list-style-type: none;
  margin-left: 3px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.cv-mobile-review-placement .preview-content .box-group .box-item-scroll .box-item .item-title {
  font-size: 12px;
  font-weight: 700;
  color: #0000cc;
  text-align: left;
}

.cv-mobile-review-placement .preview-content .box-group .box-item-scroll .box-item .item-date {
  font-size: 9px;
  font-weight: 400;
  color: #0000cc;
  text-align: left;
}

.cv-mobile-review-placement .preview-content .box-group .box-item-scroll .box-item .item-point {
  font-size: 9px;
  font-weight: 400;
  color: #0000cc;
  text-align: left;
}

.cv-mobile-review-placement .preview-content .preview-ads .ads-image {
  width: 98%;
  height: 100px;
  border-radius: 5px;
  margin: 5px 0px 5px 0px;
}

.cv-mobile-review-placement .preview-content .preview-ads .item-list {
  margin: 3px;
  padding: 0px;
  list-style-type: none;
  text-align: left;
  display: flex;
}

.cv-mobile-review-placement .preview-content .preview-ads .item-content {
  display: grid;
  width: 71%;
}

.cv-mobile-review-placement .preview-content .preview-ads .item-content .item-title {
  font-size: 12px;
  font-weight: 700;
  color: white;
}

.cv-mobile-review-placement .preview-content .preview-ads .item-content .item-donate {
  font-size: 10px;
  font-weight: 400;
  color: white;
}

.cv-mobile-review-placement .preview-content .preview-ads .item-view {
  margin-top: 5px;
  background: white;
  height: 20px;
  width: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cv-mobile-review-placement .preview-content .preview-ads .item-view .item-view-text {
  font-size: 10px;
  color: #0000cc;
  font-weight: 700;
}

.zone {
  margin-bottom: 10px;
  border-radius: 5px;
  border-top-width: 1px !important;
}

.placement-group {
  width: 100%;
  display: flex;
  overflow: auto;
  .box-item-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    .box-item {
      background-color: white;
      width: 125px;
      height: 250px;
      margin: 0px 20px 0px 0px;
      border-radius: 5px;
      text-align: center;
      .item-image {
        width: 100%;
        height: 125px;
      }
      .ads-name {
        height: 60px;
        width: 100%;
      }
      .ads {
        height: 15px;
        text-align: start;
        color: blue;
      }
    }
  }
}

.placement-group::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #000;
  border-radius: 5px;
}

.edit-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: white;
  border-radius: 5px;
  margin-right: 10px;
  .icon-size {
    width: 80%;
    height: 80%;
    cursor: pointer;
  }
}

.clicked-icon {
  background-color: #81bef7;
}

.clicked-zone {
  background-color: #eeeeee;
}

.placement-title {
  display: flex;
  .title {
    font-size: 12px;
    font-weight: 600;
    margin: 3px 5px 0px 5px;
    color: blue;
  }
  .image {
    width: 25px;
    height: 20px;
    margin-top: 3px;
  }
}

.active-post {
  height: 15px;
}

.select-category > div {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}

.input-form-select-tree-custom .input-select-tree-address .dropdown {
  width: 100%;
}

.input-form-select-tree-custom .input-select-tree-address .dropdown .dropdown-trigger {
  width: 100%;
  min-height: 40px;
  border-radius: 4px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow:after {
  float: right;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component {
  height: 250px !important;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component
  .all-item {
  padding-left: 13px !important;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component
  .all-item
  i {
  display: none !important;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component
  .provinces-item {
  padding-left: 0px !important;
}

.input-form-select-tree-custom
  .input-select-tree-address
  .dropdown
  .dropdown-content
  .root
  .infinite-scroll-component
  .district-item {
  padding-left: 20px !important;
}

.zones-view-more {
  font-style: italic;
  font-size: 16px;
  text-decoration: underline;
  margin-top: 10px;
}

.table-tiers {
  .rdt_TableRow {
    padding: 10px 0;
  }
}

.preview-object-setting-list .rdt_Table {
  min-height: 280px;
  max-height: 290px;
}

.raking-form-in-tier .raking-end-date .react-datepicker-wrapper,
.raking-form-in-tier .raking-start-date .react-datepicker-wrapper {
  width: 100%;
}

.transaction-details-margin {
  margin-left: 15px;
  width: 170px;
}

.transaction-gift-details-margin {
  margin-left: 15px;
  // width: 140px;
}

.custom-filter-time {
  height: 40px;
  width: 100%;
  border: 1px solid #4285f4;
  border-radius: 4px;
  box-shadow: #4285f4 0 0 0 1px;
  .time-heading {
    position: relative;
    padding: 0 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    cursor: default;
    outline: 0;
    .time-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
    .clear-selected-button {
      cursor: pointer;
      background: none;
      border: 0;
      padding: 0;
      display: flex;
    }
    .gray {
      color: #aaa;
    }
  }
  .custom-popup-time {
    position: relative;
    margin-top: 6px;
    height: auto;
    width: 235px;
    z-index: 100;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 20px;
  }
}

.custom-filter-time-unclicked {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 0 0;
}

.custom-filter-time-quick {
  width: 93%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 10px;
  text-align: center;
  line-height: 35px;
}

.custom-filter-time-quick-bg {
  background-color: #4285f4;
}

.loyalty-border-top {
  border-top: 1px solid #ccc;
}

.loyalty-menu-font-weight {
  font-weight: 600;
  cursor: pointer;
}

.loyalty-tab-clicked {
  background-color: #ccc;
}

.reward-suffix {
  font-weight: 600;
  padding: 5px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #ccc;
}

.reward-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.condition-table {
  .rdt_TableHeadRow .hzMSfA::after {
    content: 'Chọn tất cả';
    margin-left: 5px;
  }
  .hzMSfA {
    margin-left: 17px;
  }
}

.earning-date .react-datepicker-wrapper {
  width: 100%;
}

.game-xstt .frequency-field .indicated-frequency .indicated-date-field .react-datepicker-wrapper {
  width: 100%;
}

.icon-delete-date-frequency {
  position: relative;
}

.icon-delete-date-frequency .fa-trash {
  cursor: pointer;
  color: gray;
  font-size: 20px;
  position: absolute;
  top: 8px;
  left: 10px;
}

.gift-category {
  .rdt_TableCol {
    &:first-child,
    &:last-child {
      justify-content: flex-start;
    }
    justify-content: center;
  }
  .rdt_TableCell {
    &:first-child,
    &:last-child {
      justify-content: flex-start;
    }
    justify-content: center;
  }
}
.inp-benefit {
  width: 65px;
}
.inp-gift-section {
  width: 45px;
  display: inline-block;
}
.item-product-error {
  color: red;
  font-size: 13px;
}

.fixed-time-picker {
  z-index: 100000 !important;
  position: fixed !important;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1003;
  background: #000000;
  overflow: hidden;
}
.spinner div:first-child {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  box-shadow: 0 3px 3px 0 rgba(255, 56, 106, 1);
  transform: translate3d(0, 0, 0);
  animation: spin 2s linear infinite;
}
.spinner div:first-child:after,
.spinner div:first-child:before {
  content: '';
  position: absolute;
  border-radius: 50%;
}
.spinner div:first-child:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  box-shadow: 0 3px 3px 0 rgb(255, 228, 32);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.spinner div:first-child:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  box-shadow: 0 3px 3px 0 rgba(61, 175, 255, 1);
  animation: spin 1.5s linear infinite;
}

.recommend-notification {
  aspect-ratio: 2 / 1;
  width: 100%;
  object-fit: contain;
  float: left;
  background: #ccc;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 35px;
  font-weight: 500;
}

.notification-item {
  aspect-ratio: 2 / 1;
  width: 100%;
  object-fit: contain;
}
.mobile-screen-preview {
  width: 340px;
  height: 544px;
}
.zone-merchant-btn {
  display: flex;
  align-items: center;
}

.game-setting-line {
  white-space: nowrap;
}

.ticket-list-nowrap {
  white-space: nowrap;
  display: flex;
  flex-direction: row-reverse;
}

.custom-popover-notification {
  position: absolute;
  z-index: 9997;
  top: 53%;
  right: 65px;
  width: 600px;
  box-shadow: var(
    --ds-shadow-overlay,
    0 4px 8px -2px rgba(9, 30, 66, 0.25),
    0 0 1px rgba(9, 30, 66, 0.31)
  );
  border-radius: 5px;
  box-sizing: border-box;
}

.custom-ccard-body {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.custom-read-status-div {
  background-color: #ebf5fb;
}

.custom-card-header-notification {
  position: sticky;
  right: 0;
  top: 0;
  z-index: 9998;
}

.custom-card-notification {
  height: calc(100vh - 100px);
  margin-bottom: 0px;
}

.custom-btn-update-read-status-notification {
  position: absolute;
  right: 15px;
  top: 18px;
  z-index: 9999;
}

.custom-badge-new-unread-notification {
  position: absolute;
  padding: 5px;
  top: 15px;
}

.custom-card-body-notification {
  overflow-y: auto;
  height: calc(100vh - 265px);
}

.custom-search-icon-notification {
  position: absolute;
  z-index: 9999;
  top: 14px;
  right: 25px;
}

.custom-width {
  width: 300px;
}

.custom-width-with-btn {
  width: 280px;
}

.no-padding {
  padding: 0;
}

.no-border-bottom {
  border-bottom: 0;
}

.rdt_TableCol:has(div.user-login-table-total) {
  position: sticky;
  left: 200px;
  background-color: white;
  z-index: 1;
}

.rdt_TableCol:has(div.user-login-table-in-range) {
  position: sticky;
  left: 350px;
  background-color: white;
  z-index: 1;
}

.rdt_TableCol:has(div.user-login-table) {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}

.rdt_TableCol:has(div.bd-report-merchant-name-col-custom) {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 99;
}

.rdt_TableCol:has(div.bd-report-bd-name-col-custom) {
  position: sticky;
  left: 250px;
  background-color: white;
  z-index: 99;
}

.rdt_TableCol:has(div.bd-report-merchant-name-customer-care-col-custom) {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}

.rdt_TableCol:has(div.bd-report-bd-name-customer-care-col-custom) {
  position: sticky;
  left: 150px;
  background-color: white;
  z-index: 1;
}

.rdt_TableCol:has(div.bd-report-bd-name-number-of-stores-col-custom) {
  position: sticky;
  left: 300px;
  background-color: white;
  z-index: 1;
}

.rdt_TableCol:has(div.bd-report-customer-care-col-custom) {
  position: sticky;
  left: 400px;
  background-color: white;
  z-index: 1;
  border-right: 1px solid #7c7a78;
}

.custom-popper-datetime-picker-z-index {
  z-index: 100 !important;
}

.custom-notification-attachment-review {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px #908b8b;
}

.cv-btn-notification .btn {
  width: 150px;
  margin: 10px 0 10px 10px;
}

.cv-btn-notification {
  display: flex;
  justify-content: flex-end;
}

.custom-text-align-with-breakpoint {
  text-align: center;

  @media (max-width: 767px) {
    text-align: left;
  }
}

.customCol.col-5xl-6 {
  @media (min-width: 2000px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.customCol.mt-5xl-0 {
  @media (min-width: 2000px) {
    margin-top: 0 !important;
  }
}

.customCol.pl-5xl-0 {
  @media (min-width: 2000px) {
    padding-left: 0 !important;
  }
}

.custom-div-external-ticket {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-button-remove-img {
  color: #ff030a;
  cursor: pointer;
}

.custom-label-upload {
  cursor: pointer;
  color: #26b7d2;
  margin: 0;
}

.custom-block-control-upload-img {
  @media (max-width: 1399px) {
    max-width: 100%;
  }

  @media (min-width: 1400px) {
    max-width: 65%;
  }
}

.custom-time-picker-event-promotion > input {
  font-size: 0.875rem;
}

.custom-toggle-show-detail {
  color: #321fdb;
  cursor: pointer;
  margin-left: auto;
}

.custom-popup-time-picker .rc-time-picker-panel-combobox {
  display: flex;
}

.c-sidebar,
.c-sidebar-brand,
.c-sidebar-minimizer {
  background: #1b5c99 !important;
}
.c-sidebar-brand {
  border-bottom: 2px solid #d8dbe0 !important;
}
.c-sidebar-minimizer {
  border-top: 2px solid #d8dbe0 !important;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: #1b5c99;
}
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link.c-active {
  background: #288be5;
}
html:not([dir='rtl'])
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-item
  .c-sidebar-nav-dropdown-toggle,
html:not([dir='rtl']) .c-sidebar-nav-dropdown-items .c-sidebar-nav-item .c-sidebar-nav-link {
  border-radius: 6px;
  margin: 8px 20px 8px 30px;
  padding: 8px 10px;
}
.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: #288be5;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  font-weight: 700;
}
.c-active {
  font-weight: 700;
}
.label-description {
  font-size: 13px;
}

.merchant-mass-general-multi-select div.dropdown-content {
  z-index: 2 !important;
}

.merchant-mass-general-multi-select.multi-select-disabled {
  .dropdown-container {
    background-color: #d8dbe0;
    opacity: 1;
  }
}

.custom-subtable-spam-management:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.custom-subtable-spam-management:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.form-group.required .control-label-after:after {
  content: '*';
  color: red;
}

.custom-link-fake {
  color: #321fdb;
  cursor: pointer;
}

.custom-link-fake:hover {
  text-decoration: underline;
}

.custom-content-2-lines-datatable {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

#partner-upload-logo-image,
#partner-upload-intro-images {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-multiselect .dropdown-container[aria-disabled='true'] {
  background-color: rgb(242, 242, 242);
  pointer-events: none;
}

.preview-template-sms {
  position: relative;
  background-color: #ececec;
  border-radius: 16px;
  padding: 16px 16px 20px;
  width: 250px;
  max-width: 100%;
}

.preview-template-sms::after {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #ececec;
  position: absolute;
  bottom: -6px;
  left: 16px;
  transform: rotate(60deg);
}

.preview-template-zns {
  // background-color: #fff;
  // border-radius: 4px;
  // border: 1px solid #ececec;
  padding-bottom: 10px;
}

.preview-template-zns::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
}

.preview-template-zns::-webkit-scrollbar-track {
  border-radius: 25px;
  background-color: #f1f1f1;
}

.preview-template-zns::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 25px;
}

.preview-zns-title {
  font-weight: 700;
  margin-bottom: 1rem;
}
.preview-zns-content {
  min-height: 50px;
}

.preview-zns-cta-1 {
  color: #fff;
  background-color: #0084ff;
  text-align: center;
  padding: 4px;
  margin-bottom: 8px;
  min-height: 25px;
}

.preview-zns-cta-2 {
  color: #0059c3;
  background-color: #d5ebff;
  text-align: center;
  padding: 4px;
  min-height: 25px;
}

.params-suggestion-block .dropdown-toggle::after {
  display: none;
}

.preview-template-collapse-block {
  padding: 8px;
  margin: 16px auto;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0.154px 0.462px 0px rgba(0, 0, 0, 0.1),
    0px 0.821px 1.847px 0px rgba(0, 0, 0, 0.13);

  display: flex;
  gap: 8px;
  width: 280px;
  max-width: 100%;
}

.preview-template-logo-img {
  max-width: 40px;
  aspect-ratio: '1/1';
  border-radius: 9999px;
}

.preview-template-collapse-title {
  padding-right: 4px;
  font-weight: 700;
  min-height: 20px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.preview-template-collapse-content {
  max-width: 180px;

  *:first-child {
    padding-right: 4px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
  }

  *:not(:first-child) {
    display: none;
  }
}

.preview-template-detail-block {
  background-image: url(../../public/assets/img/phone_frame.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  position: relative;
  height: 580px;
  width: 300px;
  max-width: 100%;
  margin: 16px auto;
}

.preview-template-detail-description-block {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.preview-template-detail-description {
  width: 90%;
  height: 90%;
  overflow: hidden;
  margin: 57px auto 0px;
  padding: 0px 16px 60px;

  position: relative;
}

.preview-template-detail-content {
  padding: 4px;
  max-height: 200px;
  overflow: auto;
  font-size: 13px;
}

.preview-template-detail-btns-block {
  position: absolute;
  left: 0;
  top: 85%;

  padding: 8px 24px;
  width: 100%;

  @media (min-width: 1500px) and (max-width: 1550px) {
    top: 80%;
  }

  @media (min-width: 1400px) and (max-width: 1500px) {
    top: 70%;
  }
}

.preview-template-1-cta {
  color: #fff;
  background-color: #0084ff;
  border: 1px solid #0084ff;
  text-align: center;
  padding: 4px;
  margin-bottom: 8px;
  min-height: 25px;
  border-radius: 4px;
  width: 100%;
}

.preview-template-2-ctas {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.preview-template-2-cta-1 {
  color: #0084ff;
  background-color: #fff;
  border: 1px solid #0084ff;
  text-align: center;
  padding: 4px;
  min-height: 25px;
  border-radius: 4px;
  overflow: hidden;
}

.preview-template-2-cta-2 {
  color: #fff;
  background-color: #0084ff;
  border: 1px solid #0084ff;
  text-align: center;
  padding: 4px;
  min-height: 25px;
  border-radius: 4px;
  overflow: hidden;
}

.preview-template-banner-img {
  width: 100%;
  height: 100px;
  margin: 8px auto 0;
}
.preview-template-banner-block {
  margin: 8px auto 0;
  background-color: #ccc;
  width: 100%;
  height: 100px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interaction-banner-img-block {
  background-color: #ccc;
  width: 50%;
  height: 120px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-template-attach-references {
  font-size: 14px;
  margin-top: 8px;
}

.preview-template-content-block {
  max-height: 85%;
  overflow: auto;
  padding-right: 8px;
}

.custom-preview-auto-interaction {
  .preview-template-detail-btns-block {
    @media (min-width: 1500px) and (max-width: 1550px) {
      top: 85%;
    }

    @media (min-width: 1400px) and (max-width: 1500px) {
      top: 85%;
    }
  }
}
